import React, { useState } from 'react';
import './cssfiles/Navbar.css';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
    setIsMobileMenuOpen(false); // Close the menu after clicking a link
  };

  return (
    <nav className="navbar">
      <div className="hamburger-menu" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
        ☰
      </div>
      <ul className={`nav-links ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        <li onClick={() => scrollToSection('home-section')}>Home</li>
        <li onClick={() => scrollToSection('achievements-section')}>Achievements</li>
        <li onClick={() => scrollToSection('aboutme-section')}>About Me</li>
        <li onClick={() => scrollToSection('skills-section')}>Skills</li>
        <li onClick={() => scrollToSection('portfolio-section')}>Portfolio</li>
        <li onClick={() => scrollToSection('contact-form-section')}>Feedback</li>
        <li onClick={() => scrollToSection('contact-form-section')}>Contact</li>
      </ul>
    </nav>
  );
}

export default Navbar;
