import React from 'react';
import './cssfiles/Hero.css'
import myImageProfile from './images/profileimage-removebg.png'
import myImageSplash from './images/8Tzrbk8yc-removebg-preview.png'

function Hero() {


  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="hero" id="home-section">
      <div className="hero2">
        <div className='centered-content'>

       
        <div className="hero-text">
        <h1>Bringing Ideas to Life through &lt;Code/&gt;</h1>

  <p><code>Full-stack solutions from concept to deployment</code></p>
  <button onClick={() => scrollToSection('portfolio-section')} className="explore-button">EXPLORE NOW</button>
</div>
    <div className="hero-image">
      <div className="image-container">
        <img src={myImageProfile}  className="image-one" />
        <img src={myImageSplash}  className="image-two" style={{width:'100%',transform: 'rotate(30deg)'}} />
      </div>
    </div>
  </div>
  </div>
  </div>
  
  );
}

export default Hero;
