import React from 'react';
import './cssfiles/AboutMe.css';
import myProfileImage from './images/profileimage.jpg';
import CVFile from '../Cvfolder/Johnmary_Udogu_CV.pdf'

function AboutMe() {

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = CVFile;
    link.download = 'Johnmary-Udogu-Resume.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="about-me-container">
      <div className="profile-image">
        <img src={myProfileImage} alt="Profile"  className='aboutimg'/>
      </div>
      <div className="personal-details">
        <h2>About Me</h2>
        <h3>Johnmary Udogu</h3>
        <p className="occupation">
          <a href="#">Software Engineer</a>, <a href="#">Mobile Developer</a>, <a href="#">Full Stack Programmer</a>
        </p>
        <p className="residence">
          <strong>Residence:</strong> Nigeria / Uk<br />
          <strong>City:</strong> Lagos / London<br />
          <strong>Mobile:</strong> +2348036564774 / +44 7852355815<br />
        </p>
        <p className="bio">
        "A passionate software engineer dedicated to transforming ideas into functional solutions, all while enjoying the journey of innovation."
        </p>
        <div className="skills-tags">
          <span>Java</span><span>SpringBoot</span><span>React</span><span>Node</span><span>Python</span>
          <span>React Native</span><span>Android</span><span>Javascript</span><span>Databases</span><span>AWS</span>
        </div>
        <button onClick={handleDownload} className="download-cv" href={CVFile} download="CJUdoguresume.pdf">
          Download CV <span className="download-icon">⬇️</span>
        </button>
      </div>
    </div>
  );
}

export default AboutMe;
