import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar';
import Hero from './components/Hero';
import AchieveSkillscombo from './components/AchieveSkillscombo';
import AboutMeSkillsCombo from './components/AboutMeSkillsCombo';
import TagsProjectCardsCombo from './components/TagsProjectCardsCombo';
import ContactFormContactInfoCombo from './components/ContactFormContactInfoCombo';
import BottomBanner from './components/BottomBanner';


function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <AchieveSkillscombo />
      <AboutMeSkillsCombo />
      <TagsProjectCardsCombo />
      <ContactFormContactInfoCombo/>
      <BottomBanner />


    </div>
  );
}

export default App;
