import React, { useState } from 'react';
import './cssfiles/ProjectCards.css';

function ProjectCards() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const projects = [
    {
      image: 'https://i.imgur.com/v0ccYgv.png',
      title: 'StudyPaddy',
      description: 'Built an Ai Powered learning platform with features like quiz generation from files or texts, summarization of files and interaction with files.',
    },
    {
      image: 'https://i.imgur.com/pq5IVNe_d.jpg?maxwidth=520&shape=thumb&fidelity=high',
      title: 'Curator-1',
      description: 'An Ai powered Backend Platform that curates articles and news from sites feed around the world',
    },
    {
      image: 'https://i.imgur.com/XjRmnjp_d.jpg?maxwidth=520&shape=thumb&fidelity=high',
      title: 'Escalayt',
      description: 'A simple ticketing platform for assigning and resolving tickets within a company.',
    },
    {
      image: 'https://i.imgur.com/oIggXxO_d.jpg?maxwidth=520&shape=thumb&fidelity=high',
      title: 'Ivite',
      description: 'A cross-platform Mobile application that curates images from attendees of an event, creating a huge album that covers every aspect of the event.',
    },
  ];

  const openModal = (image) => {
    setSelectedImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className='main-container'>
      <div className="projects-container">
        {projects.map((project, index) => (
          <div 
            key={index} 
            className="project-card" 
            onClick={() => openModal(project.image)}
          >
            <img src={project.image} alt={project.title} className="project-image" />
            <div className="project-content">
              <h3 className="project-title">{project.title}</h3>
              <p className="project-description">{project.description}</p>
            </div>
          </div>
        ))}
      </div>

      {isOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content">
            <img src={selectedImage} alt="Full View" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectCards;
