import React from 'react';
import './cssfiles/Tags.css';

function Tags() {
  return (
    <div className="tags-container">
      {/* <button className="tag">HTML</button>
      <button className="tag">CSS</button>
      <button className="tag">Wordpress</button>
      <button className="tag">PHP</button>
      <button className="tag active">React JS</button>
      <button className="tag">Shopify</button>
      <button className="tag">Laravel</button> */}
      <button  className="download-cv" >
          PORTFOLIO 
        </button>
    </div>
  );
}

export default Tags;
