import React from 'react'
import Tags from './Tags'
import ProjectCards from './ProjectCards'
import './cssfiles/TagsProjectCardsCombo.css'

const TagsProjectCardsCombo = () => {
  return (
    <div className='app-container2' id="portfolio-section">
        <Tags />
        <ProjectCards />
      
    </div>
  )
}

export default TagsProjectCardsCombo
