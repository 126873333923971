import React from 'react';
import './cssfiles/Achievements.css';
import image1 from './images/developing.png';
import Image2 from './images/celebrating_2454273.png';
import image3 from './images/trophy.png';


function Achievements() {
  return (
    <div className="achievements-section" id="achievements-section">
      <h2>Achievements</h2>
      <p>
        I’ve been fortunate to work with and for people from some <span className="highlight">amazing organizations</span>
      </p>
      <ul className="achievement-list">
        <li>
          <img src={image1} alt="Icon 1" className='imgstyle' />
          <div>
            <h3>5+</h3>
            <p>5+ years of experience as a Fullstack Software Engineer.</p>
          </div>
        </li>
        <li>
          <img src={Image2} alt="Icon 2" className='imgstyle' />
          <div>
            <h3>4000+</h3>
            <p>Happy customers and users worldwide, Working with 100% client satisfaction guarantee!</p>
          </div>
        </li>
        <li>
          <img src={image3} alt="Icon 3" className='imgstyle'/>
          <div>
            <h3>10+</h3>
            <p>10+ successful projects in various industries.</p>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Achievements;
