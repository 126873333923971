import React from 'react';
import './cssfiles/BottomBanner.css';

function BottomBanner() {
  return (
    <div className="banner-container" id="bottom-section">
      <h1>... Building Innovative Solutions</h1>
      {/* <p>Build modern websites fast and easy using Now!</p> */}
    </div>
  );
}

export default BottomBanner;
