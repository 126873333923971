import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './cssfiles/ContactForm.css';
import contactImage from './images/celebrating_2454273.png'; // Replace with your actual image path

function ContactForm() {
    const [isSent, setIsSent] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_y8epavd',   // Your EmailJS Service ID
            'template_hurrhiu',  // Your EmailJS Template ID
            e.target,
            'GSFqc3BIj-9Nfm3OG'  // Your EmailJS User ID (required)
        ).then((result) => {
            console.log('Success:', result.text);
            setIsSent(true); // Show "Sent" icon
            setTimeout(() => {
                setIsSent(false); // Revert back after 3 seconds
            }, 3000);
        }, (error) => {
            console.error('Failed:', error.text);
            alert("Failed to send message. Please try again.");
        });

        e.target.reset(); // Clear the form after submission
    }

    return (
        <div className="contact-form-container" id='contact-form-section'>
            <div className="form-section">
                <h2>Contact Me:</h2>
                <form onSubmit={sendEmail}>
                    <div className="input-group">
                        <label>Name</label>
                        <input type="text" name="name" placeholder="Type your full name here" required />
                    </div>
                    <div className="input-group">
                        <label>Email</label>
                        <input type="email" name="email" placeholder="ex: hello@gmail.com" required />
                    </div>
                    <div className="input-group">
                        <label>Phone</label>
                        <input type="text" name="phone" placeholder="ex: +234908967560" />
                    </div>
                    <div className="input-group">
                        <label>Message</label>
                        <textarea name="message" placeholder="Type your full message here" required></textarea>
                    </div>
                    <button type="submit" className="send-message-button">
                        {isSent ? (
                            <span className="send-icon">✔️ Sent</span> // "Sent" icon
                        ) : (
                            <span>
                                <span className="send-icon">✉️</span> Send Message
                            </span>
                        )}
                    </button>
                </form>
            </div>
            <div className="image-section">
                <img src={contactImage} alt="Contact Illustration" />
                <div className="contact-info">
                    <div className="info-item">
                        <span className="icon">📞</span>
                        <a href="tel:+2348036564774">(+234)8036564774</a> {/* Phone number link */}
                    </div>
                    <div className="info-item">
                        <span className="icon">📧</span>
                        <a href="mailto:johnmaryudogu@gmail.com">johnmaryudogu@gmail.com</a> {/* Email link */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;
