import React from 'react'
import Achievements from './Achievements'
import SkillsLogos from './SkillsLogos'
import './cssfiles/AchieveSkillscombo.css'

const AchieveSkillscombo = () => {
  return (
    <div className="app-container90">
      <Achievements />
      <SkillsLogos />
    </div>
  )
}

export default AchieveSkillscombo
