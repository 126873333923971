import React from 'react';
import './cssfiles/ContactInfo.css';

function ContactInfo() {
  return (
    <div className="contact-info-container" id="contact-section">
      <img src="illustration.png" alt="Contact Illustration" className="contact-illustration" />
      <div className="contact-details">
        <div className="contact-item">
          <span className="contact-icon">📞</span>
          <div>
            <p>(123) 456 7890</p>
            <p>Our customer care is open from Mon-Fri | 10:00 AM to 6:00 PM</p>
          </div>
        </div>
        <div className="contact-item">
          <span className="contact-icon">📧</span>
          <div>
            <p>carlos@cybur.ai</p>
            <p>Our support team will get back to you in 48-H during standard business hours.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
