import React from 'react'
import ContactForm from './ContactForm'
import ContactInfo from './ContactInfo'
import './cssfiles/ContactFormContactInfoCombo.css'


const ContactFormContactInfoCombo = () => {
  return (
    <div className="app-container">
    <div className="contact-section">
      <ContactForm />
     
    </div>
  </div>
  )
}

export default ContactFormContactInfoCombo
