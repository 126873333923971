import React from 'react';
import './cssfiles/Skills.css';

function Skills() {
  const skills = [
    { name: "Java", percentage: 95 },
    { name: "SpringBoot", percentage: 95 },
    { name: "React", percentage: 90 },
    { name: "Node", percentage: 90 },
    { name: "Python", percentage: 90 },
    { name: "React Native", percentage: 95 },
    { name: "Android", percentage: 95 },
    { name: "Javascript", percentage: 85 },
    { name: "Databases", percentage: 80 },
    { name: "AWS", percentage: 70 }
  ];

  return (
    <div className="skills-container" id="skills-section">
      <h2>Skills:</h2>
      <div className="skills-list">
        {skills.map(skill => (
          <div key={skill.name} className="skill-item">
            <div className='skillsetup'>
            <div className="skill-name">
              {skill.name}
            </div>
            <div className="skill-bar">
              <div className="skill-level" style={{ width: `${skill.percentage}%` }}>
               
              </div>
            </div>
            <span>{skill.percentage}%</span>
          </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Skills;
