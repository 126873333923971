import React from 'react';
import './cssfiles/SkillsLogos.css';
import java from './images/javaicon.png'
import react from './images/reacticon.png'
import spring from './images/springicon.png'
import node from './images/nodeicon.png'
import python from './images/pythonicon.png'
import javascript from './images/javascripticon.png'
import android from './images/android.png'

function SkillsLogos() {
  return (
    <div className="skills-logos-section">
      <div className="central-logo">
        <img src={java} alt="WordPress" className="large-logo" />
      </div>
      <div className="small-logo logo1">
      <img src={react} alt="HTML5" className="imglogo" />
      </div>
      <div className="small-logo logo2">
        <img src={spring} alt="Android" className="imglogo" style={{transform: 'rotate(-60deg)'}} />
      </div>
      <div className="small-logo logo3">
        <img src={node} alt="Angular" className="imglogo" style={{transform: 'rotate(-120deg)'}} />
      </div>
      <div className="small-logo logo4">
        <img src={javascript} alt="Firebase" className="imglogo" style={{transform: 'rotate(-180deg)'}} />
      </div>
      <div className="small-logo logo5">
        <img src={python} alt="Python" className="imglogo" style={{transform: 'rotate(90deg)'}} />
      </div>
      <div className="small-logo logo6">
        <img src={android} alt="Shopify" className="imglogo"style={{transform: 'rotate(60deg)'}} />
      </div>
    </div>
  );
}

export default SkillsLogos;
