import React from 'react'
import AboutMe from './AboutMe'
import Skills from './Skills'
import './cssfiles/AboutMeSkillsCombo.css'

const AboutMeSkillsCombo = () => {
  return (
    <div className="app-containergh" id="aboutme-section">
    <AboutMe />
    <Skills />
  </div>
  )
}

export default AboutMeSkillsCombo
